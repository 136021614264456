<template>
    <div class="dashboard-container">
        <Breadcrumb>
            <template #title>
                <h1>Dashboard</h1>
            </template>
        </Breadcrumb>
        <div class=" card-with-shadow awesome-box flex flex-col gap-2 justify-center items-center mb-8">
            <div class="font-bold text-2xl">Hello {{ firstName }} {{ lastName }}</div>
            <div class="font-medium text-l">Welcome To Your Awesome Cogny Dashboard!</div>
        </div>
        <div class="dashboard-stats-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
            <template v-if="isLoading">
                <Skeleton height="104px" class="mb-8"/>
                <Skeleton height="104px" class="mb-8"/>
                <Skeleton height="104px" class="mb-8"/>
            </template>
            <div v-for="(card, index) in cards" :key="index" v-else>
                <StatCard class="stat-card" :title="card.title" :value="card.titleValue" :iconBgColor="icons[index].iconBgColor" :iconColor="icons[index].iconColor" :iconName="icons[index].iconName" >
                    <template #subtitle>
                        <span :class="card.positiveTrend ? 'change-positive' : 'change-negative'">
                            <span>{{card.subtitleValue}} </span> {{card.subtitle}}
                        </span>
                        <span>
                            <FontAwesomeIcon :icon="['fas', 'arrow-right']" size="lg" :class="card.positiveTrend ? 'arrow-positive' : 'arrow-negative'" fixed-width />
                        </span>
                    </template>
                </StatCard>
            </div>

        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <LineChart :chartData="sentimentChartData"/>
            <RadarChart :chartData="experimentalScoresData"/>
        </div>
        <div class=" grid grid-cols-1 lg:grid-cols-2 gap-4 hidden">
            <MostCommonLabels :data="data" sentiment="Positive" title="Why your customers choose you" />
            <MostCommonLabels :data="data" sentiment="Negative" title="Why your customers are leaving" />
        </div>
        <div class="chart-container">

        </div>
    </div>

</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import StatCard from '../components/Dashboard/StatCard.vue';
    import MostCommonLabels from '../components/Dashboard/MostCommonLabels.vue';
    import LineChart from '../components/Dashboard/LineChart.vue';
    import Skeleton from 'primevue/skeleton';
    import RadarChart from '../components/Dashboard/RadarChart.vue';
    import { useAuthStore } from '@/stores/auth';
    import { computed, onMounted, ref } from 'vue';
    import { numberToMonth } from '../utils/utils';
    import { dataAggregatorService } from '../services/dataAggregatorService';
    
    const authStore = useAuthStore()
    const firstName = ref('')
    const lastName = ref('')
    const cards = ref([])
    const sentimentDataPoints = ref([])
    const sentimentChartData = computed(() => getSentimentChartData())
    const experimentalScoresData = computed(() => getExperimentalScoresChartData())
    const experimentalScoresDataPoints = ref({})
    const isLoading= ref(true)
    const icons = [
        {
            iconBgColor: "#3939ff",
            iconColor: "#ffffff",
            iconName: "layer-group"
        },
        {
            iconBgColor: "#00045a",
            iconColor: "#ffffff",
            iconName: "face-smile"
        },
        {
            iconBgColor: "#3939ff",
            iconColor: "#ffffff",
            iconName: "lightbulb"
        }
    ];
    onMounted(async () => {
        firstName.value = authStore.user.user.first_name
        lastName.value = authStore.user.user.last_name
        const dashboardData = await dataAggregatorService.getDashboardData() 
        cards.value = dashboardData.cards
        sentimentDataPoints.value = dashboardData.sentimentDataPoints
        experimentalScoresDataPoints.value = dashboardData.experimentalScoresDataPoints
        console.log("ExperimentalScoresDataPoints",getExperimentalScoresChartData())
        isLoading.value = false
    })
    const data = [
        { 'rating': 1, 'label': 'Customer service issues', 'count': 231 },
        { 'rating': 2, 'label': 'Missing features', 'count': 231 },
        { 'rating': 3, 'label': 'Product dissatisfaction', 'count': 231 },
        { 'rating': 4, 'label': 'Pricing issues', 'count': 231 },
        { 'rating': 5, 'label': 'Complexity', 'count': 231 },
    ]
    function getSentimentChartData() {
        const labels = sentimentDataPoints.value.map(item => numberToMonth(item.month))
        const datasets = [{label:'Positive Sentiment',data:sentimentDataPoints.value.map(item => item.totalPositive),borderColor:'green'},
        {label:'Neutral Sentiment',data:sentimentDataPoints.value.map(item => item.totalNeutral),borderColor:'orange'},
        {label:'Negative Sentiment',data:sentimentDataPoints.value.map(item => item.totalNegative),borderColor:'red'}
        ]
        return {
            labels:labels,
            datasets:datasets
        }
    }
    function getExperimentalScoresChartData() {
        const labels = ['Customer Service', 'Missing Features', 'Product Dissatisfaction', 'Pricing', 'Complexity']
    
        const datasets= [
                {
                    backgroundColor: '#10376580',
                    borderColor: '#103765',
                    pointRadius: 0,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)',
                    data:[experimentalScoresDataPoints.value.averageCustomerServiceIssuesScore * 20, 
                        experimentalScoresDataPoints.value.averageMissingFeaturesScore * 20,
                        experimentalScoresDataPoints.value.averageProductDissatisfactionScore * 20, 
                        experimentalScoresDataPoints.value.averagePricingIssuesScore * 20,
                        experimentalScoresDataPoints.value.averageComplexityScore * 20] ,
                    clip: { left: 5, top: 100, right: -2, bottom: 0 }
                },
            ]
        return {
            labels:labels ,
            datasets:datasets
        }
    }
</script>

<style scoped lang="scss">
.arrow-positive {
    color: var(--green-color);
    transform: rotate(-45deg);
}

.arrow-negative {
    color: var(--red-color);
    transform: rotate(45deg);
}

.change-positive {
    color: var(--green-color);
}

.change-negative {
    color: var(--red-color);
}



</style>