<template>
    <div class="header-container">
        <div class="search-bar">
            <IconField class='hidden'>
                <InputIcon>
                    <FontAwesomeIcon :icon="['fas', 'search']" fixed-width />
                </InputIcon>
                <InputText class="search-input" v-model="searchValue" placeholder="Search" />
            </IconField>
        </div>
        <div class="details">
            <div class="sync-sources">
                <Button label="Sync Sources" @click="onSyncSources" :disabled="isSyncSourcesInProgress"></Button>
            </div>
            <div class="analyze-data">
                <Button label="Analyze Data" @click="onAnalyzeData" outlined></Button>
            </div>
            <div class="notifications hidden">
                <FontAwesomeIcon :icon="['fas', 'bell']" size="lg" fixed-width />
            </div>
            <div class="user-profile" @click="togglePopover">
                <Avatar :label="avatarLabel" class="mr-2" size="large" shape="circle" style="background-color: #00045a; color: #ffffff" />
            </div>
            <Popover ref="popover">
                <div class="flex flex-col gap-4">
                    <Button @click="isSignOutDialogVisible = true" label="Sign Out" outlined></Button>
                </div>
            </Popover>
            <div class="theme-toggle">
                <Button @click="toggleDarkMode" class="h-10 w-10">
                    <FontAwesomeIcon v-if="isDarkTheme" :icon="['fas', 'moon']" fixed-width />
                    <FontAwesomeIcon v-else :icon="['fas', 'sun']" fixed-width />
                </Button>
            </div>
                
        </div>
        <Toast />
        <Dialog v-model:visible="isSignOutDialogVisible" class="sign-out-dialog-container" modal header="Confirm Sign Out" :style="{ width: '25rem' }">
            <h4 class="">Are you sure you want to sign out?</h4>
            <div class="dialog-options">
                <Button type="button" label="Cancel" severity="secondary" @click="isSignOutDialogVisible = false"></Button>
                <Button type="button" label="Sign Out" @click="onConfirmSignOut"></Button>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
    import IconField from 'primevue/iconfield';
    import InputText from 'primevue/inputtext';
    import InputIcon from 'primevue/inputicon';
    import Popover from 'primevue/popover';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import Avatar from 'primevue/avatar';
    import { useAuthStore } from '../../stores/auth';
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    import { useLayout } from '../../composables/layout';
    import { connectorsService } from '../../services/connectorsService';
    import { feedbackAnalysisService } from '../../services/feedbackAnalysisService';
    const { toggleDarkMode, isDarkTheme } = useLayout();
    const toast = useToast();
    const router = useRouter();
    const authStore = useAuthStore();
    const searchValue = ref('');
    const popover = ref();
    const isSignOutDialogVisible = ref(false);
    const isSyncSourcesInProgress = ref(false);
    const isFeedbackAnalysisInProgress = ref(false);
    const togglePopover = (event) => {
        popover.value.toggle(event);
    }
    const avatarLabel = computed(() => {
        if(authStore?.user?.user?.first_name !== undefined && authStore?.user?.user?.last_name !== undefined){
            return authStore.user.user.first_name.slice(0,1).toUpperCase() + authStore.user.user.last_name.slice(0,1).toUpperCase()
        }else{
            return '?'
        }
    })
    function onConfirmSignOut() {
        try {
            authStore.logout();
            isSignOutDialogVisible.value = false;
            toast.add({ severity: 'success', summary: 'Success Message', detail: 'Sign out successful.', life: 2000 });
            router.push('/login');
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error signing you out. Please try again later.', life: 2000 });
        }
    }
    async function onSyncSources() {
        try{
            toast.add({ severity: 'info', summary: 'Sources sync', detail: 'Sources syncing started.This might take a while.', life: 2000 });
            isSyncSourcesInProgress.value = true;
            // enable after 3 minutes again
            // setTimeout(()=>isSyncSourcesInProgress.value = false, 60000 * 3)

            connectorsService.syncAllSources();
        }catch(error){
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error syncing sources. Please try again later.', life: 2000 });
        }finally{
        }
    }
    function onAnalyzeData() {
        try{
            toast.add({ severity: 'info', summary: 'Analyzing feedback', detail: 'Feedback analysis started. This might take a while.', life: 2000 });
            isFeedbackAnalysisInProgress.value = true;
            // enable after 3 minutes again
            setTimeout(()=>isFeedbackAnalysisInProgress.value = false, 60000 * 3)
            feedbackAnalysisService.analyzeFeedback();
        }catch(error){
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error analyzing feedback. Please try again later.', life: 2000 });
        }finally{
        }
    }
</script>

<style scoped lang="scss">
    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: var(--secondary-background-color);

        // .search-bar {
        //     width: 380px;

        //     .p-inputtext {
        //         --p-inputtext-background: var(--background-color);
        //         --p-inputtext-border-color: var(--background-color);
        //         --p-inputtext-hover-border-color: var(--border-color);
        //         --p-inputtext-focus-border-color: var(--border-color);
        //         --p-inputtext-color: var(--text-color);
        //         height: 40px;
        //         width: 100%;
        //         box-shadow: none;
        //     }
        // }

        .details {
            display: flex;
            align-items: center;
            gap: 20px;

            .notifications {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: var(--primary-color);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
            }

            .user-profile {
                cursor: pointer;
                width: 60px;
                height: 40px;
                border-radius: 50%;
                color: var(--primary-color);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    border: 1px solid var(--border-color);
                }
            }

            .options {
                display: flex;
                align-items: center;

                .option {
                    margin-right: 20px;
                    cursor: pointer;
                    color: var(--primary-color);

                }
            }
        }



    }

</style>

<style lang="scss">
    .sign-out-dialog-container {
        display: flex;
        flex-direction: column;
        width: 500px;
        height: 220px;

        .p-dialog-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        .dialog-options {
            display: flex;
            gap: 20px;
            width: 100%;

            button {
                width: 100%;
                height: 44px;
            }
        }
    }
</style>