<template>
    <div class="google-reviews-modal-container">
        <Dialog header="Add Google Reviews Source" v-model:visible="visible" class="csv-modal"  :style="{ width: '50vw' }">
            <div class="flex flex-col gap-4">
                <div class="text-l">
                    Provide your Google Play Store URL below and we will automatically import all the reviews from your store.
                </div>
                <div class="text-sm">
                    You can find it by visiting Google Play and searching for you app. It should look like this:
                </div>
                <div class="text-sm italic">
                    https://play.google.com/store/apps/details?id=xxx.xxxxxxx.xx
                </div>
                <div class="flex flex-col border-t py-2">
                    <label for="google-play-url">Your Google Play Url:</label>
                    <InputText id="google-play-url" v-model="googlePlayUrl" />
                </div>
            </div>
            <div class="py-2 flex justify-end">
                <Button label="Submit" @click="onSubmit"/>
            </div>
        </Dialog>

    </div>


</template>

<script setup>
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import Dialog from "primevue/dialog"
    import {ref} from "vue"
    import { useToast } from 'primevue/usetoast';
    const toast = useToast();

    const googlePlayUrl = ref('')
    const visible = defineModel()
    function onSubmit() {
        const id = getParameterByName(googlePlayUrl.value, 'id')
        if(id == null){
            toast.add({ severity: 'error', summary: 'Error Finding Google Play ID', detail: 'Check the URL and try again.', life: 2000 });

        }else{
            toast.add({ severity: 'success', summary: 'Google Play ID added successfully', detail: 'All the google reviews will now be fetched in the background.', life: 2000 });
            console.log("id", id)
            // TODO add endpoint to set google play id and fetch reviews
            visible.value = false
        }
    }

    function getParameterByName(url,name) {
    name = name.replace(/[\[\]]/g, '\\$&');

    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);

    if (!results) {
        return null;
    } 

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2]);
    }
</script>