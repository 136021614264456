import { map } from "d3";

export function getOriginLogo(origin) {
    if (origin === 'google-play-review') {
        return new URL('@/assets/google-play-logo-no-text.png', import.meta.url);
    } else if (origin === 'apple') {
        return new URL('@/assets/apple-logo.png', import.meta.url);
    } else if (origin === 'zoho') {
        return new URL('@/assets/zoho-logo.png', import.meta.url);
    } else {
        return '';
    }
}

export function getSourceLogo(source) {
    if (source === 'Google Reviews') {
        return new URL('@/assets/google-logo.png', import.meta.url);
    } else if (source === 'CSV') {
        return new URL('@/assets/csv-logo.png', import.meta.url);
    }
    else if (source === 'Google Play Reviews') {
        return new URL('@/assets/google-play-logo.png', import.meta.url);
    }
}

export function getSentimentImage(sentiment) {
    const sentimentImages = {
        very_negative: new URL('@/assets/sentiments/very_negative.png', import.meta.url),
        negative: new URL('@/assets/sentiments/negative.png', import.meta.url),
        neutral: new URL('@/assets/sentiments/neutral.png', import.meta.url),
        positive: new URL('@/assets/sentiments/positive.png', import.meta.url),
        very_positive: new URL('@/assets/sentiments/very_positive.png', import.meta.url),
    };
    return sentimentImages[sentiment];
}

export function getSeverityFromPriority(priority) {
    switch (priority) {
        case 'high':
            return 'danger';
        case 'medium':
            return 'warn';
        case 'low':
            return 'primary';
    }
}

export function numberToMonth(number) {
    switch (number) {
        case 1:
            return 'Jan';
        case 2:
            return 'Feb';
        case 3:
            return 'Mar';
        case 4:
            return 'Apr';
        case 5:
            return 'May';
        case 6:
            return 'Jun';
        case 7:
            return 'Jul';
        case 8:
            return 'Aug';
        case 9:
            return 'Sep';
        case 10:
            return 'Oct';
        case 11:
            return 'Nov';
        case 12:
            return 'Dec';
        default:
            return '';
    }
}
export function getSeverityFromSentimentScore(sentimentScore) {
    if (sentimentScore < 0) {
        return 'danger';
    } else if (sentimentScore < 50) {
        return 'warn';
    } else {
        return 'success';
    }
}


export function iso639ToIso3166(iso639Code) {
    const mapping = {
        'aa': 'DJ',  // Afar -> Djibouti
        'ab': 'GE',  // Abkhazian -> Georgia
        'af': 'ZA',  // Afrikaans -> South Africa
        'ak': 'GH',  // Akan -> Ghana
        'am': 'ET',  // Amharic -> Ethiopia
        'ar': 'SA',  // Arabic -> Saudi Arabia
        'as': 'IN',  // Assamese -> India
        'av': 'RU',  // Avaric -> Russia
        'ay': 'BO',  // Aymara -> Bolivia
        'az': 'AZ',  // Azerbaijani -> Azerbaijan
        'ba': 'RU',  // Bashkir -> Russia
        'be': 'BY',  // Belarusian -> Belarus
        'bg': 'BG',  // Bulgarian -> Bulgaria
        'bh': 'IN',  // Bihari -> India
        'bi': 'VU',  // Bislama -> Vanuatu
        'bm': 'ML',  // Bambara -> Mali
        'bn': 'BD',  // Bengali -> Bangladesh
        'bo': 'CN',  // Tibetan -> China
        'br': 'FR',  // Breton -> France
        'bs': 'BA',  // Bosnian -> Bosnia and Herzegovina
        'ca': 'ES',  // Catalan -> Spain
        'ce': 'RU',  // Chechen -> Russia
        'ch': 'GU',  // Chamorro -> Guam
        'co': 'FR',  // Corsican -> France
        'cr': 'CA',  // Cree -> Canada
        'cs': 'CZ',  // Czech -> Czech Republic
        'cu': 'RU',  // Church Slavic -> Russia
        'cv': 'RU',  // Chuvash -> Russia
        'cy': 'GB',  // Welsh -> United Kingdom
        'da': 'DK',  // Danish -> Denmark
        'de': 'DE',  // German -> Germany
        'dv': 'MV',  // Divehi -> Maldives
        'dz': 'BT',  // Dzongkha -> Bhutan
        'ee': 'GH',  // Ewe -> Ghana
        'el': 'GR',  // Greek -> Greece
        'en': 'US',  // English -> United States
        'eo': 'EU',  // Esperanto -> N/A (international)
        'es': 'ES',  // Spanish -> Spain
        'et': 'EE',  // Estonian -> Estonia
        'eu': 'ES',  // Basque -> Spain
        'fa': 'IR',  // Persian -> Iran
        'ff': 'SN',  // Fula -> Senegal
        'fi': 'FI',  // Finnish -> Finland
        'fj': 'FJ',  // Fijian -> Fiji
        'fo': 'FO',  // Faroese -> Faroe Islands
        'fr': 'FR',  // French -> France
        'fy': 'NL',  // Western Frisian -> Netherlands
        'ga': 'IE',  // Irish -> Ireland
        'gd': 'GB',  // Scottish Gaelic -> United Kingdom
        'gl': 'ES',  // Galician -> Spain
        'gn': 'PY',  // Guarani -> Paraguay
        'gu': 'IN',  // Gujarati -> India
        'gv': 'IM',  // Manx -> Isle of Man
        'ha': 'NG',  // Hausa -> Nigeria
        'he': 'IL',  // Hebrew -> Israel
        'hi': 'IN',  // Hindi -> India
        'ho': 'PG',  // Hiri Motu -> Papua New Guinea
        'hr': 'HR',  // Croatian -> Croatia
        'ht': 'HT',  // Haitian -> Haiti
        'hu': 'HU',  // Hungarian -> Hungary
        'hy': 'AM',  // Armenian -> Armenia
        'hz': 'NA',  // Herero -> Namibia
        'ia': 'EU',  // Interlingua -> N/A (international)
        'id': 'ID',  // Indonesian -> Indonesia
        'ie': 'EU',  // Interlingue -> N/A (international)
        'ig': 'NG',  // Igbo -> Nigeria
        'ii': 'CN',  // Sichuan Yi -> China
        'ik': 'US',  // Inupiaq -> United States
        'io': 'EU',  // Ido -> N/A (international)
        'is': 'IS',  // Icelandic -> Iceland
        'it': 'IT',  // Italian -> Italy
        'iu': 'CA',  // Inuktitut -> Canada
        'ja': 'JP',  // Japanese -> Japan
        'jv': 'ID',  // Javanese -> Indonesia
        'ka': 'GE',  // Georgian -> Georgia
        'kg': 'CG',  // Kongo -> Congo
        'ki': 'KE',  // Kikuyu -> Kenya
        'kj': 'NA',  // Kwanyama -> Namibia
        'kk': 'KZ',  // Kazakh -> Kazakhstan
        'kl': 'GL',  // Greenlandic -> Greenland
        'km': 'KH',  // Khmer -> Cambodia
        'kn': 'IN',  // Kannada -> India
        'ko': 'KR',  // Korean -> South Korea
        'kr': 'NG',  // Kanuri -> Nigeria
        'ks': 'IN',  // Kashmiri -> India
        'ku': 'IQ',  // Kurdish -> Iraq
        'kv': 'RU',  // Komi -> Russia
        'kw': 'GB',  // Cornish -> United Kingdom
        'ky': 'KG',  // Kirghiz -> Kyrgyzstan
        'la': 'VA',  // Latin -> Vatican City
        'lb': 'LU',  // Luxembourgish -> Luxembourg
        'lg': 'UG',  // Ganda -> Uganda
        'li': 'NL',  // Limburgish -> Netherlands
        'ln': 'CG',  // Lingala -> Congo
        'lo': 'LA',  // Lao -> Laos
        'lt': 'LT',  // Lithuanian -> Lithuania
        'lu': 'CD',  // Luba-Katanga -> Democratic Republic of the Congo
        'lv': 'LV',  // Latvian -> Latvia
        'mg': 'MG',  // Malagasy -> Madagascar
        'mh': 'MH',  // Marshallese -> Marshall Islands
        'mi': 'NZ',  // Maori -> New Zealand
        'mk': 'MK',  // Macedonian -> North Macedonia
        'ml': 'IN',  // Malayalam -> India
        'mn': 'MN',  // Mongolian -> Mongolia
        'mr': 'IN',  // Marathi -> India
        'ms': 'MY',  // Malay -> Malaysia
        'mt': 'MT',  // Maltese -> Malta
        'my': 'MM',  // Burmese -> Myanmar
        'na': 'NR',  // Nauru -> Nauru
        'nb': 'NO',  // Norwegian Bokmål -> Norway
        'nd': 'ZW',  // Northern Ndebele -> Zimbabwe
        'ne': 'NP',  // Nepali -> Nepal
        'ng': 'NA',  // Ndonga -> Namibia
        'nl': 'NL',  // Dutch -> Netherlands
        'nn': 'NO',  // Norwegian Nynorsk -> Norway
        'no': 'NO',  // Norwegian -> Norway
        'nr': 'ZA',  // Southern Ndebele -> South Africa
        'nv': 'US',  // Navajo -> United States
        'ny': 'MW',  // Chichewa -> Malawi
        'oc': 'FR',  // Occitan -> France
        'oj': 'CA',  // Ojibwe -> Canada
        'om': 'ET',  // Oromo -> Ethiopia
        'or': 'IN',  // Oriya -> India
        'os': 'RU',  // Ossetian -> Russia
        'pa': 'IN',  // Punjabi -> India
        'pi': 'IN',  // Pali -> India
        'pl': 'PL',  // Polish -> Poland
        'ps': 'AF',  // Pashto -> Afghanistan
        'pt': 'PT',  // Portuguese -> Portugal
        'qu': 'PE',  // Quechua -> Peru
        'rm': 'CH',  // Romansh -> Switzerland
        'rn': 'BI',  // Kirundi -> Burundi
        'ro': 'RO',  // Romanian -> Romania
        'ru': 'RU',  // Russian -> Russia
        'rw': 'RW',  // Kinyarwanda -> Rwanda
        'sa': 'IN',  // Sanskrit -> India
        'sc': 'IT',  // Sardinian -> Italy
        'sd': 'PK',  // Sindhi -> Pakistan
        'se': 'NO',  // Northern Sami -> Norway
        'sg': 'CF',  // Sango -> Central African Republic
        'si': 'LK',  // Sinhala -> Sri Lanka
        'sk': 'SK',  // Slovak -> Slovakia
        'sl': 'SI',  // Slovenian -> Slovenia
        'sm': 'WS',  // Samoan -> Samoa
        'sn': 'ZW',  // Shona -> Zimbabwe
        'so': 'SO',  // Somali -> Somalia
        'sq': 'AL',  // Albanian -> Albania
        'sr': 'RS',  // Serbian -> Serbia
        'ss': 'SZ',  // Swati -> Eswatini
        'st': 'LS',  // Southern Sotho -> Lesotho
        'su': 'ID',  // Sundanese -> Indonesia
        'sv': 'SE',  // Swedish -> Sweden
        'sw': 'KE',  // Swahili -> Kenya
        'ta': 'IN',  // Tamil -> India
        'te': 'IN',  // Telugu -> India
        'tg': 'TJ',  // Tajik -> Tajikistan
        'th': 'TH',  // Thai -> Thailand
        'ti': 'ET',  // Tigrinya -> Ethiopia
        'tk': 'TM',  // Turkmen -> Turkmenistan
        'tl': 'PH',  // Tagalog -> Philippines
        'tn': 'BW',  // Tswana -> Botswana
        'to': 'TO',  // Tonga -> Tonga
        'tr': 'TR',  // Turkish -> Turkey
        'ts': 'ZA',  // Tsonga -> South Africa
        'tt': 'RU',  // Tatar -> Russia
        'tw': 'GH',  // Twi -> Ghana
        'ty': 'PF',  // Tahitian -> French Polynesia
        'ug': 'CN',  // Uighur -> China
        'uk': 'UA',  // Ukrainian -> Ukraine
        'ur': 'PK',  // Urdu -> Pakistan
        'uz': 'UZ',  // Uzbek -> Uzbekistan
        've': 'ZA',  // Venda -> South Africa
        'vi': 'VN',  // Vietnamese -> Vietnam
        'vo': 'EU',  // Volapük -> N/A (international)
        'wa': 'BE',  // Walloon -> Belgium
        'wo': 'SN',  // Wolof -> Senegal
        'xh': 'ZA',  // Xhosa -> South Africa
        'yi': 'EU',  // Yiddish -> N/A (international)
        'yo': 'NG',  // Yoruba -> Nigeria
        'za': 'CN',  // Zhuang -> China
        'zh': 'CN',  // Chinese -> China
        'zu': 'ZA'   // Zulu -> South Africa
    };

    // Return the corresponding ISO 3166-1 alpha-2 code or undefined if not found
    if (iso639Code === undefined || iso639Code === null ||  mapping[iso639Code.toLowerCase()] === undefined || mapping[iso639Code.toLowerCase()] === null) {
        return undefined;
    }
    return mapping[iso639Code.toLowerCase()].toLowerCase();
}