import { feedbackAnalysisAPI,handleApiError } from '@/api';

export const feedbackAnalysisService = {
  async analyzeFeedback(params) {
    try {
      const { data } = await feedbackAnalysisAPI.analyzeFeedback(params);
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

};