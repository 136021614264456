import { connectorsAPI,handleApiError } from '@/api';

export const connectorsService = {
  async syncAllSources(params) {
    try {
      const { data } = await connectorsAPI.syncAllSources(params);
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};