import { feedbackAPI, handleApiError } from '@/api';
import { mapFeedbackFromBackend ,mapFeedbackListFromBackend} from '@/mappers/feedbackMapper';
import { mapPaginatorFromBackend } from '../mappers/paginatorMapper';

const feedbackService = {
  async getFeedbackList(params) {
    try {
      const { data } = await feedbackAPI.getFeedbackList(params);
      let paginator = mapPaginatorFromBackend(data);
      let feedbackItems = mapFeedbackListFromBackend(data.results);
      const formattedData = {'paginator':paginator,'items':feedbackItems};
      return formattedData;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  // TODO create a new endpoint in backend to get feedback in cluster that also fetches the cluster name of the first feedback
  //  async getFeedbackInCluster(params) {
  //   try {
  //     const { data } = await feedbackAPI.getFeedbackList(params);
  //     let paginator = mapPaginatorFromBackend(data);
  //     let feedbackItems = mapFeedbackListFromBackend(data.results);
  //     const formattedData = {'paginator':paginator,'items':feedbackItems};
  //     return formattedData;
  //   } catch (error) {
  //     throw handleApiError(error);
  //   }
  // },
  async getFeedbackItem(id) {
    try {
      const { data } = await feedbackAPI.getFeedbackItem(id);
      let feedbackItem = mapFeedbackFromBackend(data)
      return feedbackItem;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async likeFeedback(id) {
    try {
      const { data } = await feedbackAPI.likeFeedback(id, { 'is_liked': true });
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async unlikeFeedback(id) {
    try {
      const { data } = await feedbackAPI.likeFeedback(id, { 'is_liked': false });
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async getSavedFeedback(params) {
    try {
      const { data } = await feedbackAPI.getSavedFeedback(params);
      let paginator = mapPaginatorFromBackend(data);
      let feedbackItems = mapFeedbackListFromBackend(data.results);

      const formattedData = {'paginator':paginator,'items':feedbackItems};
      return formattedData;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async uploadFeedback(file) {
    try {
      const response = await feedbackAPI.uploadFeedback(file);
      return response.data.feedback_count;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};

export default feedbackService;