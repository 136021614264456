import { mlcoreAPI,handleApiError } from '@/api';

export const mlcoreService = {
  async getClusters(params) {
    try {
      const { data } = await mlcoreAPI.getClusters(params);
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};