import { createWebHashHistory, createRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth';

import Login from '../pages/Login.vue'
import Dashboard from '../pages/Dashboard.vue'
import FeedbackList from '../pages/FeedbackList.vue'
import SavedFeedback from '../pages/SavedFeedback.vue'
import Settings from '../pages/Settings.vue'
import Feedback from '../pages/Feedback.vue'
import Clusters from '../pages/Clusters.vue';
import Cluster from '../pages/Cluster.vue';
import Sources from '../pages/Sources.vue';
const routes = [
  { path: '/', redirect: '/dashboard' },
  {
    path: '/login',
    component: Login,
    name: "Login",
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: "Dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: '/feedback-list',
    component: FeedbackList,
    name: "FeedbackList",
    meta: { requiresAuth: true },
  },
  {
    path: '/saved-feedback',
    component: SavedFeedback,
    name: "SavedFeedback",
    meta
    : { requiresAuth: true },
  },
  {
    path: '/sources',
    component: Sources,
    name: "Sources",
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    component: Settings,
    name: "Settings",
    meta: { requiresAuth: true },
  },
  {
    path: '/feedback/:id',
    component: Feedback,
    name: "Feedback",
    meta: { requiresAuth: true },
  },
  {
    path: '/clusters',
    component: Clusters,
    name: "Clusters",
    meta: { requiresAuth: true },
  },
  {
    path: '/cluster/:id',
    component: Cluster,
    name: "Cluster",
    meta: { requiresAuth: true },
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    await authStore.checkAuth();

    if (!authStore.isAuthenticated) {
      next({ name: 'Login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});



export default router;