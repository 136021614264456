<template>
    <div class="sources-container">
        <Breadcrumb>
            <template #title>
                <h1>Sources </h1>
            </template>
        </Breadcrumb>
        <div class="sources-content card-with-shadow">
            <SourceRow source="CSV" description="Import feedback from a CSV file" @addSource="onAddSource" />
            <SourceRow source="Google Play Reviews" description="Import feedback from Google Play Store" @addSource="onAddSource" />
        </div>
        <CSVModal v-model="csvModalVisible" />
        <GooglePlayReviewsModal v-model="googlePlayReviewsVisible" />

    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import SourceRow from '../components/Sources/SourceRow.vue';
    import CSVModal from '../components/Sources/modals/CSVModal.vue';
    import GooglePlayReviewsModal from '../components/Sources/modals/GooglePlayReviewsModal.vue';
    function onAddSource(source) {
        console.log("source", source)
        if (source === 'CSV') {
            csvModalVisible.value = true;
            console.log("csvModalVisible", csvModalVisible.value)
        } else if (source === 'Google Play Reviews') {
            googlePlayReviewsVisible.value = true;
        }
    }
    const csvModalVisible = ref(false)
    const googlePlayReviewsVisible = ref(false)

</script>